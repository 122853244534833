import React from 'react'
import { Link, graphql } from 'gatsby'
import Img, { GatsbyImageProps } from 'gatsby-image'
import slugify from 'slugify'
import { FaChevronRight } from 'react-icons/fa'
import classNames from 'classnames'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'

import { Container } from '../components/Container'

interface Props {
  data: {
    people: {
      nodes: Array<{
        id: string
        name: string
        title: string
        email: string
        phoneNumbers: Array<string>
        picture: GatsbyImageProps
      }>
    }
  }
}

function personSlug(name: string) {
  const nameSlug = slugify(name, { replacement: '-', lower: true })
  return `/medarbetare/${nameSlug}/`
}

function Medarbetare(props: Props) {
  const people = props.data.people.nodes

  return (
    <Layout spaceTop={true}>
      <SEO title="Medarbetare" />
      <Container>
        <h1 className="font-serif text-3xl text-gray-700 pb-3">Medarbetare</h1>
        <hr />

        <h2 className="pt-3 text-gray-800 font-sans">Advokat Roine Sievo i samarbete med advokat Kristian Ehrling.</h2>

        <section className="pt-8">
          <div className="md:flex md:-mx-6">
            {people.map((person, idx) => (
              <div
                key={idx}
                className={classNames('w-full max-w-sm px-6', {
                  'mb-5 md:mb-0': idx === 0,
                })}
              >
                <div className="border-2">
                  <Link to={personSlug(person.name)}>
                    <Img fluid={person.picture.fluid} />
                  </Link>
                  <div className="p-3">
                    <h3 className="font-serif text-gray-800 text-2xl mb-2">{person.name}</h3>
                    {person.phoneNumbers.map((number, idx) => (
                      <p key={idx} className="text-sm">
                        {number}
                      </p>
                    ))}
                    <div className="mt-2 mb-3">
                      <a href={`mailto:${person.email}`} className="text-blue-600 hover:underline">
                        {person.email}
                      </a>
                    </div>
                    <Link to={personSlug(person.name)} className="block bg-blue-900 text-white shadow py-2">
                      <span className="flex items-center justify-center">
                        Mer info
                        <FaChevronRight style={{ top: 1, left: 10 }} className="relative" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default Medarbetare

export const pageQuery = graphql`
  {
    people: allDatoCmsPerson(sort: { fields: position }) {
      nodes {
        id
        name
        title
        email
        phoneNumbers
        picture {
          fluid(maxWidth: 400) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`
